import { default as ai_45form_45builderMusq7OKt2VMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editk1owQBl5LuMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexbalgtM4hHHMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as index47py7vF6GMMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexJPp9rEuIItMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharewr1rEspTcdMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsKk3Rvd3lFyMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsN1uVFkk5ACMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showt54qjwKKskMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestsN7WuSB3AaMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexG5ZCNSB4JrMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeE8z8vULMriMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/home.vue?macro=true";
import { default as indexV1zAjfaWDIMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/index.vue?macro=true";
import { default as loginUKG8zt4mqZMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/login.vue?macro=true";
import { default as callbackAgJghBaTwHMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as emailBGU2DlLrOPMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93GeOiviST6IMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricing3TdiQyDznYMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyfXjX0ExfC6Meta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as registerR0GftOkUpoMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokensVQmpfqZAEDMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as account2Dgqow4kl5Meta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as adminxP96CULPVpMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingCd9Jt7oGYRMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connections2Cylo9sKb8Meta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as indexNKDmk18ZcnMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordCrKniGXWHjMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profileKyYjHuxGLCMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspace1CfUVZfHQoMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingseQ0MtqK6riMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/settings.vue?macro=true";
import { default as error8JxHcZ9CGAMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as successD2cWcGJMG0Meta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93WPV34aIbL7Meta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexBx71fEbXtlMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_931zG3jQs3f9Meta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesKAPOQGBQRmMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_9396ypcE3LVrMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsxARspOwIkKMeta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsA1zOOyJcd4Meta } from "/codebuild/output/src3980508324/src/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderMusq7OKt2VMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editk1owQBl5LuMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showt54qjwKKskMeta?.name,
    path: "/forms/:slug()/show",
    meta: showt54qjwKKskMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: index47py7vF6GMMeta?.redirect,
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexJPp9rEuIItMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: sharewr1rEspTcdMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsKk3Rvd3lFyMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionsN1uVFkk5ACMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestsN7WuSB3AaMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexG5ZCNSB4JrMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeE8z8vULMriMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginUKG8zt4mqZMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailBGU2DlLrOPMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93GeOiviST6IMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricing3TdiQyDznYMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyfXjX0ExfC6Meta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerR0GftOkUpoMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingseQ0MtqK6riMeta?.name,
    path: "/settings",
    meta: settingseQ0MtqK6riMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: account2Dgqow4kl5Meta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminxP96CULPVpMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingCd9Jt7oGYRMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connections2Cylo9sKb8Meta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexNKDmk18ZcnMeta?.redirect,
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordCrKniGXWHjMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profileKyYjHuxGLCMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspace1CfUVZfHQoMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: error8JxHcZ9CGAMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successD2cWcGJMG0Meta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesKAPOQGBQRmMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsxARspOwIkKMeta || {},
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/codebuild/output/src3980508324/src/OpnForm/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]